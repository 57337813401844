import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Navbar from '../components/navbar';
import { Footer } from '../components/footer';
import '../styles/global.css';
import '../styles/faq.css';
import SEO from '../SEO';
import { pageMeta } from '../utils/common';
import BlockText from '../components/blockText/BlockText';
import CardPengaduan from '../components/cardPengaduan';
import Wrapper from '../components/Wrapper';
import GTM_TRACKERS from '../../constants/gtm-trackers';

export const query = graphql`
  query MyQuery {
  allRestApiContentFaq {
    nodes {
      data {
        faq {
          _id
          faq_order_number
          question
          categories {
            _key
            _ref
            _type
          }
          answer {
            _key
            _type
            level
            listItem
            style
            markDefs {
              _key
              _type
              href
            }
            children {
              _key
              _type
              marks
              text
            }
          }
        }
        category_order_number
        title
      }
    }
  }
}
`;

const gtmMap = new Map([
  ['Umum', GTM_TRACKERS.FAQ.UMUM],
  ['Keamanan Dana ', GTM_TRACKERS.FAQ.KEAMANAN_DANA],
  ['Informasi Pribadi', GTM_TRACKERS.FAQ.INFORMASI_PRIBADI],
  ['Proses Transaksi', GTM_TRACKERS.FAQ.PROSES_TRANSAKSI],
  ['Advis E-Deposito', GTM_TRACKERS.FAQ.ADVIS],
  ['Bilyet Deposito', GTM_TRACKERS.FAQ.BILYET],
  ['Jangka Waktu Penyimpanan Data', GTM_TRACKERS.FAQ.JANGKA_WAKTU],
  ['Deposito BPR Syariah', GTM_TRACKERS.FAQ.BPRS],
]);

export default function FAQ({ data }) {
  const { allRestApiContentFaq } = data;
  const [activeContentIndex, setActiveContentIndex] = useState('0');

  return (
    <Wrapper>
      <SEO
        title={pageMeta.faqTitle}
        metaDescription={pageMeta.faqDesc}
      />
      <Navbar />
      <div className="container mx-auto px-0 my-0 pt-10 lg:w-2/3 text-center">
        <h1 className="font-bold text-blue-light40 text-3xl lg:text-5xl">Apakah Anda Memiliki Pertanyaan?</h1>
        <h3 className="text-lg">Punya pertanyaan seputar produk dan layanan Komunal?
          Temukan berbagai informasi yang tersedia di bawah ini.
        </h3>
      </div>
      <div className="container mx-auto flex flex-row justify-center flex-wrap">
        {allRestApiContentFaq.nodes[0].data.map((value, index) => (value.faq.length === 0 ? null : (
          <div className={gtmMap.get(value.title)}>
            <button
              type="button"
              aria-label={value.title}
              className={`tab ${index.toString() === activeContentIndex ? 'active' : 'bg-blue-light40'}`}
              data-index={index}
              onClick={(e) => setActiveContentIndex(e.target.dataset.index)}
            >
              {value.title}
            </button>
          </div>
        )))}
      </div>
      <div className="container p-3 lg: py-5 mx-auto">
        {allRestApiContentFaq.nodes[0].data[activeContentIndex].faq.map((faqItem, index) => (
          <div className="faq-section">
            <p className="font-bold"><span>{index + 1}.</span> {faqItem.question}</p>
            <BlockText body={faqItem.answer} useBreakline={false} />
          </div>
        ))}

      </div>
      <CardPengaduan />
      <Footer showConnectWithUs={false} />
    </Wrapper>
  );
}

FAQ.propTypes = {
  data: PropTypes.object.isRequired,
};
